import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  openPlaystoreAgent() {
    window.open (
      'https://play.google.com/store/apps/details?id=com.crf.agent_builder');
  }
  openPlaystoreOwner() {
    window.open (
      'https://play.google.com/store/apps/details?id=com.crf.clorent_factory');
  }

  openAppstoreOwner() {
    window.alert('Comming Soon');
  }

  openAppstoreAgent() {
    window.open (
      'https://apps.apple.com/in/app/clorent-factory-agent-builder/id1670704178');

   
  }
}
