<footer class="footer">
  <div class="row footer-row">
    <div class="col-md-5 col-sm-3 col-3 align-self-center d-flex justify-content-end">
      <div style="width: 100%;margin-left: 50%;">Support <address>

        <a href="mailto:contact@clorentfactory.com">contact@clorentfactory.com</a><br>
        <a href="tel:+917775028877">(+91) 7775028877</a>
      </address></div>
     <!-- <br> -->
      <div style="width: 100%;margin-left: 50%; cursor: pointer;text-underline-position: below;"><u
          (click)="policy()">Policy And Privacy</u> </div> 
      <div style="width: 100%;margin-left: 10%;">Copyright &#169;2023 CloRent Factory Pvt. Ltd. All Rights Reserved
      </div>
    </div>

    <div class="col-md-1 col-sm-1 col-1">
      <hr>
    </div>
    <div class="col-md-6 col-sm-8 col-8 align-self-center" style="padding-top: 20px;">
      <!-- <form method="post">
        <h3>Drop Us a Message </h3>
       <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <input type="text" name="txtName" class="form-control" placeholder="Your Name *" value="" />
                </div>
                <div class="form-group">
                    <input type="text" name="txtEmail" class="form-control" placeholder="Your Email *" value="" />
                </div>
                <div class="form-group">
                    <input type="number" name="txtPhone" class="form-control" placeholder="Your Phone Number *" value="" />
                </div>
                <div class="form-group">
                    <input type="submit"  value="Send Message" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <textarea name="txtMsg" class="form-control" placeholder="Your Message *" style="width: 100%; height: 150px;"></textarea>
                </div>
            </div>
        </div>
    </form> -->
      <!-- <b> CLORENT FACTORY PRIVATE LIMITED</b> <br>
      <b>OFFICE NO 204, WING- C3,<br> BRAMHA MAJESTIC SR NO 15/1-2 <br>
        NIBM ROAD KONDHWA KH <br>
        Pune, Maharashtra 411048
        India</b>
      <address>

        <a href="mailto:contact@clorentfactory.com">contact@clorentfactory.com</a><br>
        <a href="tel:+919168682309">(+91) 9168682309</a>
      </address> -->
      <form id="contact-form" role="form">



        <div class="controls">
          <h3>Drop Us a Message </h3>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <!-- <label for="form_name">Firstname *</label> -->
                <input id="form_name" type="text" name="name" class="form-control"
                  placeholder="Please enter your Full Name *" required="required" data-error="Firstname is required.">

              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <!-- <label for="form_email">Email *</label> -->
                <input id="form_email" type="email" name="email" class="form-control"
                  placeholder="Please enter your email *" required="required" data-error="Valid email is required.">

              </div>
            </div>
          </div>
          <div class="row">


          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <!-- <label for="form_message">Message *</label> -->
                <textarea id="form_message" name="message" class="form-control" placeholder="Write your message here."
                  rows="2" required="required" data-error="Please, leave us a message."></textarea>
              </div>

            </div>
            <div class="col-md-6">
              <!-- <div class="form-group">
                <label for="form_message">Message *</label>
                <textarea id="form_message" name="message" class="form-control" placeholder="Write your message here."
                  rows="4" required="required" data-error="Please, leave us a message."></textarea>
              </div> -->
              <input type="submit" class="btn btn-success btn-send  pt-2 btn-block
              " value="Send Message"(click)="submit()">
            </div>



          </div>


        </div>
      </form>
      <div>

      </div>
    </div>
  </div>
</footer>