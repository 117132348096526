<div class="container-fluid p-0">
  <div style="width: 100%;padding: 0px 20px;text-align: center;">
    <div>
      <span class="link-header">
        CloRent Factory Agent Builder <br> Get Access to Direct owner Listing Verified Rental and Resale Listings <br> Directly from owners
      </span>
    </div>
    <div style="margin-top: 10px;">
      <img class="app-logo" src="../../assets/images/android.png" alt="" (click)="openPlaystoreAgent()">
      <br>
      <img class="app-logo" src="../../assets/images/apple.png" alt="" (click)="openAppstoreAgent()">
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col-md-12">
      <img class="banner" src="../../assets/images/Society TV-Banner.jpg" alt="">
      <div class="row">
        <div class="col-md-1 col-sm-1 col-1"></div>
        <div class="col-md-10 col-sm-10 col-10">
          <div class="banner-text">
            <h1 style="text-shadow: 2px 2px #000;"><b>Resedential Rent<br>
                Resedential Sell <br>
                Commercial Rent<br>
                Commercial Sell
              </b></h1>
          </div>
        </div>
        <div class="col-md-1 col-sm-1 col-1"></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-1 col-sm-1 col-1"></div>
    <div class="col-md-10 col-sm-10 col-10 header">
      <h1><b>CloRent Factory</b></h1>
      <h4>To provide complete service in real estate brokerage with in-depth market knowledge and ethical practices by
        developing and selling of real estate as cost effective as possible while maintaining the highest level of
        service.</h4>
      <p>

        <li>Clorent Factory offers a complete portfolio of real estate services including sales and leasing of
          residential and commercial property, investments sales and acquisitions, New and Old project sales and
          marketing, property consultancy Legal services and mortgages, Finance services.</li>
        <li>Clorent Factory has built its reputation on financial strength, the ability to respond quickly to
          opportunities and requirements in the market place and a history of positive performance on behalf of our
          every clients.</li>


    </div>
    <div class="col-md-1"></div>
  </div>

  <div class="row features">
    <div class="col-md-1 col-sm-1 col-1"></div>
    <div class="col-md-5 col-sm-12 col-12 text-center">
      <div>
        <img src="../../assets/images/Property Count.jpg" alt="" width="30%">
        <img src="../../assets/images/PropertyList.jpg" alt="" width="30%">
        <img src="../../assets/images/ProjectList.jpg" alt="" width="30%">
      </div>

    </div>
    <div class="col-md-5 col-sm-12 col-12 m-to">
      <div class="row">
        <div class="col-md-3 col-sm-3 col-3">
          <img class="features-img" src="../../assets/images/SVG/Digital Board 96.svg" alt="">
        </div>
        <div class="col-md-9 col-sm-9 col-9 features-text">
          <h5><b>Information About Product</b></h5>
          <p></p>
          <li>Add Commercial and Resedential properties for rent and sell</li>
          <li>Add Commercial and Resedential properties requirements for rent and sell</li>
          <li>Show properties in all listing different types of prpperty types</li>
          <li>Property list perticular listing type.</li>
          <li>Maticing properties with you property.</li>
          <li>Show builder projects</li>
          <li>Filter Property</li>
          <li>Shortlist your favorite property</li>
        </div>
      </div>
    </div>
    <div class="col-md-1 col-sm-1 col-1"></div>
  </div>

  <div class="row features">
    <div class="col-md-1"></div>
    <div class="col-md-5 col-sm-12 col-12 m-to">
      <div class="row">
        <div class="col-md-3 col-sm-3 col-3">
          <img class="features-img" src="../../assets/images/SVG/Infotainment 96.svg" alt="">
        </div>
        <div class="col-md-9 col-sm-9 col-9 features-text">
          <h5><b>Protection Of Your Personal Infomation And General Infomation</b></h5>

          <li><b style="font-size: 25x !important;font-weight: 600;"> Security Measures :</b> In order to protect both
            the Personal Information and the general information that we
            receive from you through our Website, we have implemented various security measures, to protect your
            Personal Information and to limit the risk that it will be accessed without authorization, including use of
            certain industry standard technologies and practices.</li>
          <li>Risk of Interception : Please keep in mind, however, that whenever you give out Personal Information
            online there is a risk that third parties may intercept and use that information. While we strive to protect
            your Personal Information and privacy, we cannot guarantee the security of any information you disclose
            online.</li>
        </div>
      </div>
    </div>
    <div class="col-md-5 col-sm-12 col-12 m-to">
      <div class="row">
        <div class="col-md-3 col-sm-3 col-3">
          <img class="features-img" src="../../assets/images/SVG/96.svg" alt="">
        </div>
        <div class="col-md-9 col-sm-9 col-9 features-text">
          <h5><b>About Us</b></h5>
          <p><b> CLORENT FACTORY PRIVATE LIMITED</b> <br>
            <b>OFFICE NO 204, WING- C3,<br> BRAMHA MAJESTIC SR NO 15/1-2 <br>
              NIBM ROAD KONDHWA KH <br>
              Pune, Maharashtra 411048
              India</b>
          <address>

            <a href="mailto:contact@clorentfactory.com">contact@clorentfactory.com</a><br>
            <a href="tel:+917775028877">(+91) 7775028877</a>
          </address>
        </div>
      </div>
    </div>
    <div class="col-md-1"></div>
  </div>
  <div style="height: 2px;background-color: #343a40;width: 100%;margin-top: 10px;"></div>
  <div class="row features" style="margin-bottom: 20px;">
    <div class="col-md-1"></div>
    <div class="col-md-5 col-sm-12 col-12 m-to">
      <div class="row">
        <div class="col-md-3 col-sm-3 col-3">
          <img class="features-img" src="../../assets/images/SVG/Location 96.svg" alt="">
        </div>
        <div class="col-md-9 col-sm-9 col-9 features-text">
          Applcations On Play Store And App Store:
          <li> CloRent Factory Agent and Builder</li>
          <li> CloRent Factory </li>
        </div>
      </div>
    </div>
    <div class="col-md-5 col-sm-12 col-12 m-to">
      <div class="row">
        <div class="col-md-3 col-sm-3 col-3">
          <!-- <img class="features-img" src="../../assets/images/SVG/96.svg" alt=""> -->
        </div>
        <!-- <div class="col-md-9 col-sm-9 col-9 features-text">
          <h5><b>Download Applications From Here.....</b></h5>
          <div style="display: flex;">
            <img class="app-logo" src="../../assets/images/android.png" alt="" (click)="openPlaystoreAgent()">
            <img class="app-logo" src="../../assets/images/android.png" alt="" (click)="openPlaystoreOwner()">
          </div>
          <div style="display: flex; margin-top: 10px;">
            <img class="app-logo" src="../../assets/images/apple.png" alt="" (click)="openAppstoreAgent()">
            <img class="app-logo" src="../../assets/images/apple.png" alt="" (click)="openAppstoreOwner()">
          </div>

        </div> -->
      </div>
    </div>
    <div class="col-md-1"></div>
  </div>
  <a href="https://api.whatsapp.com/send?phone=917775028877&text=I would like to know more about “CloRent Factory“"
    target="_blank" class="float">
    <i class="fa fa-whatsapp my-float"></i>
  </a>
</div>