import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor( ) { }

  ngOnInit(): void {
    
  }
  policy(){
    window.open('https://admin.clorentfactory.com/privacy-policy')
  }
  submit(){
alert('out Support team will contact with you shortly ... Thank You!!')
  }
}
