<header>
  <nav class="navbar fixed-top navbar-light bg-dark">
    <div class="navbar-header">
      <a class="navbar-brand" routerLink="/">
        <i><img src="../../assets/images/AppIcon1.png" alt="SocietyTV Logo"></i>
        <span style="font-size: 25px;color: #bcbcbc;">CloRent Factory</span>
      </a>
    </div>

  </nav>


</header>
